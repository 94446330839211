import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AiFillCaretDown, AiFillCaretUp, AiFillStepForward } from 'react-icons/ai';
import {
  Button,
  ListGroup,
  Form,
} from 'react-bootstrap';
import parse from 'html-react-parser';

import { objectElfsquadUrl } from '../../redux/factory';
import { stripHtml } from '../../utils/utils';
import { use } from '../../redux/factory';
import {
  LoadingIndicator,
  SmallSpinner,
  CreateEditModal as EditModal,
  DeleteConfirmButton,
  CreateFileButton,
  EditButton,
  CopyButton,
  useSetState,
  useLocalization,
} from '../shared/ReactToolbox';
import CopyObjectsModal from './CopyObjectsModal';
import CreateObjectModal from './CreateObjectModal';
import useFlatObjectsList from './useFlatObjectsList';

export const ObjectDescription = (
  ({ object, link=false }) => {
    const { text } = useLocalization();
    const { configurationModelsList } = use.configurationModels();
    if (!object) return null;

    const configurationModel = configurationModelsList[object.configuration_model_uuid];

    const description = <>
      <strong>
        {object.order + 1}: {object.name}
        &nbsp;
      </strong>
      ({configurationModel ? stripHtml(configurationModel.description) : <i>{text`not_found`}</i>}
      &nbsp;-&nbsp;
      {object.id})
    </>;
    return (link
      ? (
        <a
          href={objectElfsquadUrl(object)}
          target="_blank"
          rel="noreferrer"
        >
          {description}
        </a>
      ) : description
    );
  });

const ObjectsList = ({ phase }) => {
  const [state, setState] = useSetState({
    objectsSelected: {},
    objectsInCopyModal: null,
    createModalActive: false,
    editObject: null,
  })
  const { strings, text } = useLocalization();
  const { userFactory } = use.factories();
  const { configurationModelsList } = use.configurationModels();
  const { selectedProjectId, getExports } = use.projects();
  const {
    objectsList,
    getAllObjects,
    updateObject,
    exportObjects,
    configureObject,
    clearObjectsList: clearObjectsListBase,
    deleteManyObjects: deleteManyObjectsBase,
  } = use.objects({ phase });
  const flatObjectsList = useFlatObjectsList();

  useEffect(
    () => {
        if (!configureObject.isLoading) {
          // Prevent clearing the objects list when this component is mounted for the first time and
          // an object is still being configured as this will clear the loading state and make it possible
          // that an object can be configured twice in CreateObjectModal
          clearObjectsList();
        }
    },
    // Do not add configureObject to the array of dependencies
    []
  );

  const clearObjectsList = () => {
    if (selectedProjectId !== null && !objectsList && !getAllObjects.isLoading) {
      clearObjectsListBase({
        project: selectedProjectId,
        phase: phase ? phase.id : 'null',
      })
    }
  }

  const deleteManyObjects = objectsSelected => {
    if (!objectsSelected || objectsSelected.length === 0) {
      // This should not happen and if it does prevent error
      console.error('No object to delete.')
      return;
    }

    deleteManyObjectsBase(
      objectsSelected, { phase },
      { callback: () => setState({ objectsSelected: {} }) }
    );
  }

  const onSelectClick = ({ id }, checked) => {
    setState({
      objectsSelected: {
        ...state.objectsSelected,
        [id]: checked,
      }
    })
  }

  const onExportObjectsClick = ({ newFlowStepId, objectsSelected }) => {
    exportObjects(
      { flowStepId: newFlowStepId, objects: objectsSelected, phase },
      { callback: () => {
          setState({ objectsSelected: {} });
          getExports( selectedProjectId )
        },
      }
    );
  }
  const setStatus = {}; // Can this be deleted?

  if (
    getAllObjects.isLoading
    || (!objectsList && objectsList !== null)
    || !configurationModelsList
    || !userFactory
    || !flatObjectsList
  ) return <LoadingIndicator />;

  const { objectsSelected } = state;
  const {
    flow_steps,
    can_decrease_status,
    force_unique_object_name,
  } = userFactory;

  const objectsByStatus = {};
  const objectsSelectedByStatus = {};
  const minFlowStepId = flow_steps[0] ? flow_steps[0].id : null;
  let maxFlowStepId = 1;
  // First create a object with flow_step_id as key and empty array as value
  flow_steps.map(({ id: flow_step_id }) => {
    objectsByStatus[flow_step_id] = [];
    objectsSelectedByStatus[flow_step_id] = [];
    maxFlowStepId = Math.max(maxFlowStepId, flow_step_id);
  });

  // Populate the object with the list of objects
  Object.values(objectsList || {}).map(obj => {
    // Turn value into boolean and turn undefined into false
    const selected = !!objectsSelected[obj.id];
    if (!objectsByStatus[obj.flow_step_id]) {
      // An object has a flow_step_id that it not a valid status. This is not supposed to happen, however
      // make sure the application doesn't crash by ignoring this object altogether
      return;
    }
    objectsByStatus[obj.flow_step_id].push({ ...obj, selected });
    if (selected) {
      objectsSelectedByStatus[obj.flow_step_id].push(obj);
    }
  });

  const StatusButton = ({ children, newFlowStepId, objectsSelected, ...restProps }) => <>
    <Button
      {...restProps}
      variant="primary"
      size="sm"
      className="icon-button"
      disabled={objectsSelected.length === 0 || setStatus.isLoading}
      onClick={e => {
        // Prevent accordion from changing when clicking this button
        e.stopPropagation();
        onExportObjectsClick({ newFlowStepId, objectsSelected });
      }}
    >
      {children}
    </Button>
  </>

  const StatusHeader = ({ flowStepId, statusIndex, statusName, objectsSelected }) => {

    // Find out if all of the selection checkboxes are checked
    const allChecked = !objectsByStatus[flowStepId].find(({ id }) => !state.objectsSelected[id]);
    // Find out if none of the selection checkboxes are checked
    const noneChecked = !objectsByStatus[flowStepId].find(({ id }) => state.objectsSelected[id]);
    const hasObjects = objectsByStatus[flowStepId] && objectsByStatus[flowStepId].length > 0;
    return (
      <ListGroup.Item className='objects-list-header'>
        {hasObjects &&
          <Form.Check
            type="checkbox"
            className="float-start object-checkbox"
            checked={!noneChecked}
            style={!noneChecked && !allChecked ? { opacity: '0.5' } : {}}
            onClick={e => e.stopPropagation()}
            onChange={e => {
              setState({
                objectsSelected: {
                  ...state.objectsSelected,
                  ...objectsByStatus[flowStepId].reduce((selected, { id }) => {
                    // Deselect only when all the checkboxes are checked, otherwise check them all
                    selected[id] = e.target.checked || !allChecked;
                    return selected;
                  }, {}),
                },
              });
            }}
          />
        }
        {statusName}

        {hasObjects && false && `(${objectsByStatus[flowStepId].length})`}

        <span className="float-end">
          {setStatus.isLoading && <SmallSpinner />}

          {hasObjects &&
            <>
              <DeleteConfirmButton
                variant="primary"
                title={text`delete_objects`}
                modalTitle={text`delete_objects`}
                onDelete={() => deleteManyObjects(objectsSelected)}
                disabled={noneChecked}
              />
              <CopyButton
                title={text`copy_objects`}
                variant="primary"
                className="icon-button-space"
                disabled={noneChecked}
                onClick={() => setState({objectsInCopyModal: objectsSelectedByStatus[flowStepId] })}
              />
            </>
          }
          {flowStepId === minFlowStepId &&
            <CreateFileButton
              title={text`configure_new_object`}
              variant="primary"
              size="sm"
              onClick={() => setState({ createModalActive: true })}
            />
          }
          {hasObjects && <>
            {can_decrease_status && flowStepId !== minFlowStepId &&
              <StatusButton
                title={text`export_this_selection_and_lower_status`}
                newFlowStepId={flow_steps[statusIndex - 1].flow_step_id}
                objectsSelected={objectsSelected}
              >
                <AiFillCaretUp />
              </StatusButton>
            }
            <StatusButton
              title={text`export_this_selection`}
              newFlowStepId={flowStepId}
              objectsSelected={objectsSelected}
            >
              <AiFillStepForward />
            </StatusButton>
            {flowStepId < maxFlowStepId &&
              <StatusButton
                newFlowStepId={flow_steps[statusIndex + 1].id}
                title={text`export_this_selection_and_raise_status`}
                objectsSelected={objectsSelected}
              >
                <AiFillCaretDown />
              </StatusButton>
            }
          </>
        }
        </span>
      </ListGroup.Item>
    );
  }

  return (getAllObjects.isLoading
    ? <LoadingIndicator />
    : <>
      <ListGroup onClick={e => e.stopPropagation()}>
        {flow_steps
          .sort((a, b) => a.order > b.order ? 1 : -1)
          .map(
          ({ id: flow_step_id, localization_key: lk }, index) => <Fragment key={index}>
            <StatusHeader
              statusName={lk && (strings.getString(lk) || lk)}
              flowStepId={flow_step_id}
              statusIndex={index}
              objectsSelected={objectsSelectedByStatus[flow_step_id]}
            />
                  {objectsByStatus[flow_step_id]
                    .sort((a, b) => a.order > b.order ? 1 : -1)
                    .map((obj, key) => (
                      <ListGroup.Item key={key}>
                        <Form.Check
                          type="checkbox"
                          className="float-start object-checkbox"
                          checked={!!objectsSelected[obj.id]}
                          onChange={e => onSelectClick(obj, e.target.checked)}
                        />
                          <ObjectDescription object={obj} link={true}/>
                        <span className="float-end">
                          <EditButton
                            title={text`edit_object`}
                            onClick={() => setState({ editObject: obj })}
                          />
                          <DeleteConfirmButton
                            title={text`delete_object`}
                            modalTitle={text`delete_object`}
                            onDelete={() => deleteManyObjects([obj])}
                          />
                        </span>

                        {state.editObject && state.editObject.id === obj.id &&
                          <EditModal
                            modalTitle={text`edit_object`}
                            onHide={() => setState({ editObject: null })}
                            initialState={obj}
                            validate={(state) => (
                              { name:
                                  force_unique_object_name
                                  && flatObjectsList.find(
                                    ({ name, id }) => name === state.name && id !== obj.id
                                  )
                                    ? text`object_name_not_unique_error`
                                    : false
                              }
                            )}
                            formFields={{ name: {
                              label: text`name`,
                              formProps: { maxLength: 32 },
                            } }}
                            onSave={object => updateObject(
                              object,
                              { callback: () => setState({ editObject: null }) }
                            )}
                            loading={updateObject.isLoading}
                          />
                        }
                      </ListGroup.Item>
                    )
                  )}
          </Fragment>
        )}
      </ListGroup>
      <CopyObjectsModal
        phase={phase}
        show={!!state.objectsInCopyModal}
        objects={state.objectsInCopyModal}
        onHide={() => setState({ objectsInCopyModal: null })}
      />
      <CreateObjectModal
        phase={phase}
        show={state.createModalActive}
        onHide={() => setState({ createModalActive: false })}
      />
    </>
  );
};
ObjectsList.propTypes = {
  phase: PropTypes.object,
};
ObjectsList.defaultProps = {
  phase: null,
};

export default ObjectsList;
