import axios from '../utils/axios';
import { toastOnError } from '../utils/utils';
import reduxCrudFactory from './ReduxCrudFactory';
import { getCurrentUser } from '../components/shared/Login';
import { dispatchError } from '../components/shared/ReactToolbox';

export const objectElfsquadUrl = obj => `https://ems.elfsquad.io/orderentry/configure?featuremodel=${obj.configuration_model_uuid}&configuration=${obj.uuid}&quotation=${obj.quotation_uuid}&fromCache=false`;
export const quotationElfsquadUrl = ({ quotation_uuid }) => `https://ems.elfsquad.io/quotation/details/${quotation_uuid}`;

const factory = reduxCrudFactory({
  axios,
  onError: e => {
    dispatchError(e);
    toastOnError(e);
  },
  actions: {
    get: true,
    getList: true,
    create: true,
    update: true,
    delete: true,
  },
  config: {
    clients: {
      route: '/api/clients/',
      actions: {
        select: 'single',
        get: true,
        getList: true,
        create: true,
        update: true,
        delete: true,
      },
    },
    configurationModels: {
      route: '/api/configuration-models/',
      id: 'featureModelId',
      actions: { getList: true },
    },
    factories: {
      route: '/api/factories/',
      state: {
        activeObjectSelections: null,
        userFactory: null,
      },
      includeActions: {
        changeUserFactory: {
          method: 'post',
          route: factory => `/api/factories/${factory.id}/set/`,
          onResponse:
            (factory,
              {
                dispatch,
                getState,
                setUserFactory,
                clearClientsList,
                getClientsList,
                clearProjectsList,
                clearAllPhases,
                clearAllObjects,
                clearActiveObjectSelections,
                getActiveObjectSelections,
                getConfigurationModelsList,
              }) => {
                getCurrentUser()(dispatch, getState);
                setUserFactory(factory)
                clearClientsList();
                clearProjectsList();
                clearAllPhases();
                clearAllObjects();
                clearActiveObjectSelections();
                getClientsList();
                getActiveObjectSelections();
                getConfigurationModelsList();
              },
        },
        getUserFactory: {
          method: 'get',
          route: '/api/factories/user-factory/',
          onResponse: (factory, { setUserFactory }) => setUserFactory(factory),
        },
        getActiveObjectSelections: {
          method: 'get',
          route: '/api/factories/active-object-selections/',
          onResponse:
            (activeObjectSelections, { setActiveObjectSelections }) =>
              setActiveObjectSelections(activeObjectSelections),
        },
      },
    },
    objects: {
      route: '/api/objects/',
      parent: 'phase',
      actions: {
        getAll: true,
        getList: true,
        create: false,
        update: true,
        delete: true,
      },
      includeActions: {
        configureObject: {
          isAsync: true,
          route: `/api/objects/create/`,
          method: 'post',
          prepare: ({ configurationModelUuid, project, phase, name }) => ({
            configuration_model_uuid: configurationModelUuid,
            project: project.id,
            phase: phase ? phase.id : null,
            name,
          }),
          onResponse: (object, { setObject, getState }) => {
            const selectedProjectId = getState().projects.selectedId;
            if (selectedProjectId === object.project) {
              setObject(object);
              const handle = window.open(objectElfsquadUrl(object), '_blank');
              if (handle !== null) {
                handle.focus()
              }
            }
          },
        },
        exportObjects: {
          isAsync: true,
          route: `/api/objects/export/`,
          method: 'post',
          prepare: ({ flowStepId, objects }) => ({ flow_step_id: flowStepId, objects }),
          onResponse: (objects, { setObject }) => {
            for (const o of objects) {
              setObject(o);
            }
          },
        },
        deleteManyObjects: {
          isAsync: true,
          parent: (objects, { phase }) => phase ? phase.id : null,
          route: `/api/objects/delete/`,
          method: 'post',
          onResponse: ({ objects }, { setAllObjects }) => setAllObjects(objects),
        },
        // Copy a object to a different phase. The backend will generate new quotation_uuid. The new object can
        // be given a new name
        copyObject: {
          isAsync: true,
          // parent: (object, { fromPhase }) => fromPhase ? fromPhase.id : null,
          prepare: (object, { args: { name, phase } }) => ({
            name,
            phase: phase ? phase.id : null,
          }),
          route: ({ id }) => `/api/objects/${id}/copy/`,
          method: 'post',
          onResponse: (object, { setObject }) => setObject(object),
        },
        // Copy an array of objects to a different phase. The backend will generate new quotation_uuids.
        copyMultipleObjects: {
          isAsync: true,
          parent: (objects, { args: { fromPhase } }) => fromPhase ? fromPhase.id : null,
          prepare: (objects, { args: { toPhase } }) => ({
            objects,
            phase: toPhase ? toPhase.id : null,
          }),
          route: `/api/objects/copy/`,
          method: 'post',
          onResponse: (objects, { setObject }) => {
            for (const o of objects) {
              setObject(o);
            }
          },
        },
        moveObject: {
          isAsync: true,
          route: object =>  `/api/objects/${object.id}/move/`,
          method: 'put',
          prepare: (object, { args: { target, position } }) => ({ target, position }),
          onResponse: (objects, { setAll }) => setAll(objects),
        },
      },
    },
    phases: {
      route: '/api/phases/',
      parent: 'parent',
      recursive: true,
      actions: {
        getAll: true,
        select: 'single',
        getList: true,
        create: true,
        update: true,
        delete: true,
      },
      includeActions: {
        movePhase: {
          isAsync: true,
          route: phase =>  `/api/phases/${phase.id}/move/`,
          method: 'put',
          prepare: (phase, { args: { target, position } }) => ({ target, position }),
          onResponse: (phases, { setAll }) => setAll(phases),
        },
      },
    },
    projects: {
      route: '/api/projects/',
      actions: {
        select: 'single',
        getList: true,
        create: true,
        update: true,
        delete: true,
      },
      includeActions: {
        copyProject: {
          isAsync: true,
          route: project => `/api/projects/${project.id}/copy/`,
          prepare: (project, { args: { newProject }, getState }) => ({
            project: newProject,
          }),
          method: 'post',
          // Handle response data from axios and trigger action to add (set) project project to the redux store
          onResponse:
            (
              project,
              {
                setProject,
                clearProjectsList,
                getProjectsList,
                selectProject,
                clearAllObjects,
                clearAllPhases,
                selectClient,
                data,
              }
            ) => {
              // data is the first argument given to copyProject which is the
              // project to copy from
              if (project.client === data.client) {
                // Same client, just set and select project
                setProject(project);
                selectProject(project);
              } else {
                // Different client, clean up state
                clearAllObjects();
                clearAllPhases();
                clearProjectsList()
                selectClient(project.client);
                getProjectsList({
                  params: { client: project.client },
                  callback: () => selectProject(project),
                });
              }
            },
        },
        createProjectFromXml: {
          isAsync: true,
          route: '/api/projects/from-xml/',
          prepare: xml => ({ xml }),
          onResponse: ({ project, client }, { setProject, getState }) => {
            const { selectedId: selectedClientId } = getState().clients;
            if (client.id === selectedClientId) {
              setProject(project);
            }
            return { project, client };
          },
          method: 'post',
        },
        getExports: {
          isAsync: true,
          route: project => `/api/projects/${typeof project === 'object' ? project.id : project}/exports/`,
          onResponse: (exports, { setExports }) => setExports(exports),
          method: 'get',
        },
      },
      state: {
        exports: null,
      },
    },
    languages: {
      route: '/api/languages/',
      actions: {
        getList: true,
        get: true,
        update: true,
        create: true,
        delete: true,
      },
      includeActions: {
        getLocalizationStrings: {
          route: '/api/localization/',
          isAsync: true,
          onResponse: (ls, { setLocalizationStrings }) => setLocalizationStrings(ls),
        },
        // Update single localization string
        updateLocalizationString: {
          route: '/api/localization/',
          method: 'put',
          isAsync: true,
        },
        // Update all localization string at the same time
        updateAllLocalizationStrings: {
          route: '/api/localization/',
          method: 'post',
          isAsync: true,
          onResponse: (ls, { setLocalizationStrings }) => setLocalizationStrings(ls),
        },
        setUserLanguage: {
          route: '/api/users/set-language/',
          method: 'post',
          prepare: language => ({ language }),
        },
        moveLanguage: {
          isAsync: true,
          route: ({ language }) =>  `/api/languages/${language.id}/move/`,
          method: 'put',
          prepare: ({ target, position }) => ({ target, position }),
          onResponse: (objs, { setLanguagesList }) => setLanguagesList(objs),
        },
      },
      state: {
        localizationStrings: null,
      },
    },
    localizationKeys: {
      route: '/api/localization-keys/',
      actions: {
        getList: true,
        get: true,
        update: true,
        create: false,
        delete: false,
      },
      includeActions: {
        moveLocalizationKey: {
          isAsync: true,
          route: ({ localizationKey }) =>  `/api/localization-keys/${localizationKey.id}/move/`,
          method: 'put',
          prepare: ({ target, position }) => ({ target, position }),
          onResponse: (objs, { setLocalizationKeysList }) => setLocalizationKeysList(objs),
        },
      },
    },
    localizationStrings: {
      route: '/api/localization-strings/',
      actions: {
        getList: true,
        get: true,
        update: true,
        create: true,
        delete: true,
      },
    },
    emails: {
      route: '/api/emails/',
      parent: 'language',
      actions: {
        getAll: true,
        getList: true,
        get: true,
        update: true,
        create: false,
        delete: false,
      },
      includeActions: {
        sendTestEmail: {
          isAsync: true,
          route: ({ id }) =>  `/api/emails/${id}/send-test/`,
          method: 'post',
        },
      },
    },
  },
});

export default factory;

export const actions = factory.actions;
export const mapToProps = factory.mapToProps;
export const use = factory.hooks;
