import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, Modal, Button } from 'react-bootstrap';
import _ from 'lodash';

import { SmallSpinner, useSetState, usePrevious, useLocalization } from '../shared/ReactToolbox';
import SelectPhase from '../phases/SelectPhase';
import { use } from '../../redux/factory';

const initialState = {
  selectedPhase: null,
  name: '',
};

const CopyObjectsModal = ({
  phase,
  show,
  objects,
  onHide: onHideBase,
}) => {
  const [state, setState] = useSetState({
    ...initialState,
    ...objects && objects.length === 1 ? { name: objects[0].name } : {},
  })
  const { copyObject, copyMultipleObjects } = use.objects({ phase });
  const { text } = useLocalization();

  const prevObjects = usePrevious(objects);
  useEffect(() => {
    if (objects && !_.isEqual(objects, prevObjects)) {
      setState({ name: objects.length === 1 ? objects[0].name : ''})
    }
  }, [objects, prevObjects])

  const onCopy = (e) => {
    if (objects.length === 1) {
      copyObject(
        objects[0],
        {
          args: {
            name: state.name,
            phase: state.selectedPhase,
          },
          callback: () => {
            onHide();
            toast.success(text`object_copied_successfully`);
          },
        }
      );
    } else {
      copyMultipleObjects(
        objects,
        {
          args: {
            fromPhase: phase,
            toPhase: state.selectedPhase,
          },
          callback: () => {
            onHide();
            toast.success(text`objects_copied_successfully`);
          },
        }
      );
    }
  }

  const onHide = () => {
    setState(initialState, () => onHideBase());
  }

  const { selectedPhase } = state;
  if (!objects) return null;
  const isLoading = copyObject.isLoading || copyMultipleObjects.isLoading;

  return (
    <Modal
      show={show}
      onHide={onHide}
      onClick={e => e.stopPropagation()}
      dialogClassName="mw-100 w-50"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{
          objects.length === 1
            ? text`copy_single_object`
            : (text`copy_multiple_objects`).replace('{count}', objects.length)
          }</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {objects.length === 1 &&
          <Form.Group controlId="name">
            <Form.Label>{text`new_name`}</Form.Label>
            <Form.Control
              as="input"
              autoComplete="off"
              value={state.name || ''}
              onChange={e => setState({ name: e.target.value })}
              onKeyPress={e => {
                if (e.charCode === 13 && !isLoading) {
                  // Pressing the enter key will save data unless it is a multi line text area
                  e.preventDefault();
                  onCopy();
                }
              }}
            />
          </Form.Group>
        }
        <SelectPhase
          currentPhase={phase}
          selectedPhase={selectedPhase}
          onSelectPhase={selectedPhase => setState({ selectedPhase })}
        />
      </Modal.Body>

      <Modal.Footer>
        {isLoading && <SmallSpinner />}
        <Button
          variant="secondary"
          onClick={onHide}
        >
          {text`close`}
        </Button>
        <Button
          variant="primary"
          onClick={onCopy}
          disabled={isLoading}
        >
          {text`copy`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CopyObjectsModal;
